.custom-icon {
    background: none;
    border: none;
    font-size: 24px;
    text-align: center;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leaflet-container {
    z-index: 10 !important;
}

.fixed.right-0 {
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
}
.hidden-sidebar {
    transform: translateX(100%);
}

.leaflet-container {
    background: lightgray;  /* ✅ Ajoute un fond pour voir si la carte charge */
    z-index: 10 !important;
}


.animate-fadeIn {
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

